import { createApp } from 'vue';
import App from './App.vue';
import { createVuestic } from 'vuestic-ui';
import { createPinia } from 'pinia';
import piniaPersist from 'pinia-plugin-persist';
import 'vuestic-ui/css';
import router from './router';
import '@/styles/app.scss';
import BaseBreadcrumb from '@/components/BaseBreadcrumb.vue';

const pinia = createPinia();
pinia.use(piniaPersist);
const app = createApp(App);
app.component('BaseBreadcrumb', BaseBreadcrumb);

app.use(
  createVuestic({
    config: {
      colors: {
        primary: '#9333EA',
        'chart-color-1': '#EF4444',
        'chart-color-2': '#FBBF24',
        'chart-color-3': '#7C3AED',
        'chart-color-4': '#22C55E',
        'chart-color-5': '#3B33F8',
        'chart-color-6': '#6CCCF7',
        'chart-color-7': '#C17529',
        'chart-color-8': '#166534',
        'chart-color-9': '#FF98FB',
        'chart-color-10': '#E5E7EB',
      },
    },
  }))
  .use(router)
  .use(pinia)
  .mount('#app');
